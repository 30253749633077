import { requestConnectionStatus } from "@shared/api/external";

export const isConnected = () => {
  // @ts-ignore
  if (window?.xdbwallet) {
  // @ts-ignore
    return window.xdbwallet;
  }

  return requestConnectionStatus();
};
